import React from "react";
import Skeleton from "react-loading-skeleton";

import * as S from "./Dropdown.styled";

const DropdownSkeleton = () => {
  return (
    <S.DropdownSkeletonWrapper>
      <Skeleton width={400} height={22} />
    </S.DropdownSkeletonWrapper>
  );
};

export default DropdownSkeleton;

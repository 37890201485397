import { atom } from "recoil";

import { localStorageEffect } from "./hooks";

const recentSearchCountryCode = atom<string[]>({
  key: "recentSearchCountryCode",
  default: [],
  effects: [localStorageEffect<string[]>("recentSearchCountryCode")],
});

export { recentSearchCountryCode };
